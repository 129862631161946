
























































































































































import {
  defineComponent, onMounted, onBeforeUnmount, computed, ref, watch,
} from '@vue/composition-api';
import { FCCart } from '@fc/angie-ui';
import FCButton from '@/shared/components/FCButton.vue';
import { INavigationTab } from '~/components/navigation/types';
import { useCartContext } from '~/composables/useCartContext';

export default defineComponent({
  name: 'DefaultNavBar',
  components: {
    CartDrawer: () => import('~/components/checkout/components/CartDrawer.vue'),
    MobileDrawer: () => import('~/components/navigation/MobileDrawer.vue'),
    DesktopSupportMenu: () => import('~/components/navigation/DesktopSupportMenu.vue'),
    Section: () => import('~/components/navigation/Section.vue'),
    SubSection: () => import('~/components/navigation/SubSection.vue'),
    FCCart,
    FCButton,
  },
  props: {
    breakpoint: {
      type: Number,
      default: -99999,
    },
  },
  setup(props) {
    const scrollPosition = ref(0);
    const showAnnouncement = computed(() => {
      const announcementBreak = props.breakpoint > 0 ? props.breakpoint : 100;
      return (scrollPosition.value > announcementBreak && !isMenuOpen.value);
    });

    const isMenuOpen = ref(false);

    // @ts-expect-error
    // eslint-disable-next-line no-undef
    const { $store } = useNuxtApp();

    const data = computed(() => $store.getters.navigationData);
    const navigationData = computed(() => data?.value?.Data[0].content.filter((section: INavigationTab) => section.component !== 'NavigationSupportTab') || []);

    const supportNavigationData = data?.value?.Data[0].content.filter((section: INavigationTab) => section.component === 'NavigationSupportTab')[0] || {};

    const { cartqty, isCartEmpty, cartDrawerOpen } = useCartContext();

    function handleScroll() {
      if (process.client) {
        scrollPosition.value = window.scrollY;
      }
    }

    const onHomePage = ref(false);
    const activeTab = ref('');
    const route = computed(() => $store.getters.currentRoute);
    watch(route, (newVal) => {
      if (newVal) {
        isMenuOpen.value = false;
        activeTab.value = '';
      }

      if (newVal.path === '/') {
        onHomePage.value = true;
      } else { onHomePage.value = false; }
    }, { deep: true });

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
      handleScroll();

      if (route.value.path === '/') { onHomePage.value = true; }
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    function mobileMenuToggle() { isMenuOpen.value = !isMenuOpen.value; }

    function toggleDesktopSection(section: INavigationTab, event?: KeyboardEvent) {
      event?.preventDefault();
      if (activeTab.value === section.title) {
        handleNavClose();
      } else {
        activeTab.value = section.title;
      }
    }

    function toggleDrawer(event?: KeyboardEvent) { event?.preventDefault(); $store.commit('toggleDrawer'); }

    function handleNavClose() { activeTab.value = ''; }

    function getLinkName(elName: string) {
      return `${route.value.name}_NavigationBar_${elName}`;
    }

    const showShopButton = computed(() => $store.getters.showShopButton);

    function isActiveSubSection(section: INavigationTab) { return section.content[0].component === 'NavigationSubSection' && activeTab.value === section.title; }
    function isActiveSection(section: INavigationTab) { return section.content[0].component === 'NavigationSection' && activeTab.value === section.title; }

    const isNavActive = computed(() => activeTab.value !== '');

    return {
      showShopButton,
      cartqty,
      isCartEmpty,
      cartDrawerOpen,
      isMenuOpen,
      showAnnouncement,
      activeTab,
      onHomePage,
      mobileMenuToggle,
      toggleDesktopSection,
      toggleDrawer,
      handleNavClose,
      getLinkName,
      isActiveSubSection,
      isActiveSection,
      isNavActive,
      navigationData,
      supportNavigationData,
    };
  },
});
